import type { CSSProperties } from 'react'

interface IconSpaceProps {
    className?: string
    isShared?: boolean
    isSecure?: boolean
    style?: CSSProperties
    isLogo?: string
}

const IconSpace = ({ className, isSecure = false, isShared = false, isLogo, style }: IconSpaceProps) => {

    return (
        <svg
            xmlns="http://www.w3.org/svg/2000"
            viewBox="0 0 38.742 40.279"
            style={style}
            className={className}
            fill="current"
        >
            <path d="M0 37.02V3.176L23.8 0v3.374c.053 0 .106-.007.16-.007h12.196a2.59 2.59 0 0 1 2.587 2.587v28.268a2.59 2.59 0 0 1-2.587 2.587H23.961c-.054 0-.107 0-.16-.007v3.478Zm23.8-1.705a1.051 1.051 0 0 0 .16.017h12.196a1.11 1.11 0 0 0 1.108-1.109V5.954a1.11 1.11 0 0 0-1.108-1.109H23.961a1.178 1.178 0 0 0-.16.016v8.651h11.15a.49.49 0 0 1 0 .979H23.8v2.938h10.959a.489.489 0 1 1 0 .978H23.8v2.91h10.959a.49.49 0 1 1 0 .979H23.8v3h10.959a.49.49 0 1 1 0 .979H23.8Z" />
            {isSecure ? (
                <path
                    d="M4.973.355A.555.555,0,0,1,4.629.22,1.1,1.1,0,0,0,3.907,0C3.23,0,2.753.97,2.442,1.985,1.392,2.178.71,2.491.71,2.842c0,.3.491.568,1.281.761a3.135,3.135,0,0,0-.038.482,3.006,3.006,0,0,0,.133.888H1.008a.3.3,0,0,0-.3.3.275.275,0,0,0,.022.111l.861,2.151A3.952,3.952,0,0,0,0,10.707a.659.659,0,0,0,.659.659H9.286a.659.659,0,0,0,.659-.659A3.959,3.959,0,0,0,8.354,7.533l.859-2.151a.319.319,0,0,0,.022-.111.3.3,0,0,0-.3-.3H7.859a3.006,3.006,0,0,0,.133-.888A3.134,3.134,0,0,0,7.954,3.6c.79-.193,1.281-.464,1.281-.761,0-.351-.682-.664-1.732-.857C7.193.97,6.716,0,6.038,0a1.1,1.1,0,0,0-.722.22A.56.56,0,0,1,4.973.355ZM5.95,10.3,5.4,8.1a.7.7,0,0,1,.08-.537l.349-.582a3.029,3.029,0,0,0,1.447-.944H7.8l-.44,1.1a1.063,1.063,0,0,0,.353,1.25A2.894,2.894,0,0,1,8.853,10.3H5.95ZM4.118,6.982l.349.582a.7.7,0,0,1,.08.537L4,10.3h-2.9A2.885,2.885,0,0,1,2.231,8.387a1.069,1.069,0,0,0,.353-1.25l-.442-1.1h.528A3.029,3.029,0,0,0,4.118,6.982Zm.855-.944a1.957,1.957,0,0,1-1.84-1.3.879.879,0,0,0,.6.231H4a.85.85,0,0,0,.806-.582.169.169,0,0,1,.322,0,.852.852,0,0,0,.806.582h.278a.879.879,0,0,0,.6-.231A1.957,1.957,0,0,1,4.973,6.038Z"
                    fill="#fff"
                    transform="translate(7 15)"
                />
            ) : (
                isShared && isLogo && (
                    <image
                    href={isLogo}
                    x="1"
                    y="8"
                    width="20"
                    height="20"
                    style={{ position: 'absolute', zIndex: 1 }}
                />
                )
            )}
        </svg>
    )
}

export default IconSpace
