import { faRefresh, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Waiting from 'assets/wait'
import useDebounce from 'core/debounce'
import { observer } from 'mobx-react-lite'
import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Invitation } from 'stores/partners'
import { useMst } from 'stores/store'
import Admin from '../admin/admin'
import Pagination from '../admin/pagination'
import Tooltip from '../shared/tooltip'

const InvitationLine = ({ invitation, isManager, onRefresh, partnerId }: { invitation: Invitation; isManager: boolean; onRefresh: () => void, partnerId: string }) => {
    const { t } = useTranslation()
    const [, setLoading] = useState<boolean>(false)
    const { partners } = useMst()

    const onInvitationDelete = async () => {
        setLoading(true)
        if (confirm(t('web_admin_confirm_delete_invite'))) {
            await partners.removeInvitation(partnerId, invitation.uuid)
        }
        setLoading(false)
        onRefresh()
    }

    const onInvitationRefresh = async () => {
        setLoading(true)
        await partners.refreshInvitation(partnerId, invitation.uuid)
        setLoading(false)
        onRefresh()
    }

    return (
        <tr>
            <td>{invitation.email}</td>
            {isManager && <td>{invitation.manager?.fullname ?? ''}</td>}
            <td>{invitation.confidential ? '✓' : '✗'}</td>
            <td className="text-ming flex justify-center">
                <Tooltip tooltip={<span>{t('web_partner_invite_pending')}</span>}>
                    <div>
                        <Waiting className="size-6 cursor-help" />
                    </div>
                </Tooltip>
            </td>
            <td className="space-x-2">
                <FontAwesomeIcon icon={faTrash} className="text-christine cursor-pointer" onClick={() => onInvitationDelete()} />
                <FontAwesomeIcon icon={faRefresh} className="text-christine cursor-pointer" onClick={() => onInvitationRefresh()} />
            </td>
            <td>{invitation.updatedAt.replace('T', ' ').split('.')[0]}</td>
        </tr>
    )
}

const limit = 50

const Case: FC = observer(() => {
    const { partners, user } = useMst()
    const { t } = useTranslation()
    const { id } = useParams()
    const [loading, setLoading] = useState<boolean>(true)

    const [offset, setOffset] = useState<number>(0)
    const [query, setQuery] = useState<string>('')
    const [page, setPage] = useState<number>(0)
    const [pageCount, setPageCount] = useState<number>(0)

    const [isManager, setIsManager] = useState<boolean>(false)

    const load = useCallback(async () => {
        setLoading(true)
        await partners.loadInvitations(id, limit, offset, query)

        setPageCount(Math.ceil(partners.totalUsers / limit))

        setLoading(false)
    }, [offset, query])

    const changePage = async page => {
        setPage(page)
        setOffset(Math.ceil(page * limit))
    }

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            load()
        }
    }

    const debouncedQuery = useDebounce<string>(query, 500)
    useEffect(() => {
        load()
    }, [page, debouncedQuery])

    useEffect(() => {
        setPage(0)
        const isManager = user.managingPartners.find(partner => partner.uuid === id)?.isManager ?? false
        setIsManager(isManager)
    }, [id])

    return (
        <Admin
            isLoading={loading}
            title={t('web_partner_my_cases')}
            header={
                <div className="flex items-center space-x-4">
                    <Link to={`/partner/${id}/invite`} className="btn">
                        {t('web_partner_invite')}
                    </Link>
                    <div className="flex items-center rounded border bg-white px-4 py-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2 size-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>
                        <input
                            type="text"
                            className="m-0 grow border-none bg-transparent p-0 text-sm focus:border-none focus:outline-none"
                            placeholder={t('web_dashboard_search')}
                            onChange={e => setQuery(e.currentTarget.value)}
                            onKeyDown={e => onKeyDown(e)}
                        />
                    </div>
                </div>
            }>
            <table className="w-full table-auto text-center">
                <thead>
                    <tr>
                        {/* <th>{t('web_menu_admin_users_name')}</th> */}
                        <th>{t('web_menu_admin_users_email')}</th>
                        {isManager && <th>{t('web_partner_manager')}</th>}
                        <th>{t('web_menu_admin_users_confidential')}</th>
                        <th>{t('web_menu_admin_users_status')}</th>
                        <th>{t('web_menu_admin_users_actions')}</th>
                        <th>{t('web_partner_invite_last_update')}</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {partners.users.map(user => (
                        <UserLine key={user.uuid} user={user} isManager={isManager} />
                    ))} */}
                    {partners.invitations.map(invitation => (
                        <InvitationLine key={invitation.uuid} invitation={invitation} isManager={isManager} onRefresh={load} partnerId={id} />
                    ))}
                </tbody>
            </table>
            <div className="mt-8 flex w-full justify-center">
                {pageCount > 1 && <Pagination page={page} totalPages={pageCount} onChangePage={changePage} />}
            </div>
        </Admin>
    )
})

export default Case
